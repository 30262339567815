

.footer-container {
    background-color: #717d99;
    display: flex;
    height: 10vh;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}


.footer-left {
    padding-left: 10px;
}


.footer-right {
    padding-right: 10px;

}