.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}

.App-logo {
  height: 50px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

}

.header-menu-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  padding: 30px;
  margin: 0 60px;
}

.help-button {
  padding: 20px; 
  background-color: #ffdf5ccc;
  font-size: 30px;
  font-weight: 900;
  border-radius: 10px;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.help-panel {
  padding: 5px 25px;
  /* text-align: left; */
}

.html {
  /* overflow-x: hidden; */
}

.error-panel {
  min-height: 80vh;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 40px;
}

.img-row {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 10px;
  justify-content: center;
}

.robot-img {
  width: 33vw;
}

.sad-img {
  width: 33vw;
  filter: invert(98%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

