.card-board {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-auto-rows: minmax(100px, auto);
  padding: 1rem 3%;
}

.cardImg {
  width: 90%;
  align-items: center;
  justify-content: center;
  padding: 4%;

}

.faceDownCardImg {
}

.card-container {
  position: relative;
  text-align: center;
  color: white;
  cursor: pointer;

}

.centered-overlap-text {
  position: absolute;
  /* top: 50%; */
  /* color: #18191A; */

  text-shadow: 0 0 1vw #000; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 1vw #000;
  -webkit-text-shadow: 0 0 1vw #000;

  /* text-shadow: stroke( black) */
  color: black;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  font-size: 7vw;
}

.hidden {
  display: none;
}

.jack-card {

}
