
.face-cards-found-container {
    display: flex;
    justify-content: space-around;
    padding: 7px;

}

.card-found-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 7px;
    margin: 7px;
}

.face-card-name {
    width: 100%;
}